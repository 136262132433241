// src/utils/translate.ts

export const translateCondition = (condition: string): string => {
  switch (condition) {
    case 'new':
      return 'Novo';
    case 'used':
      return 'Usado';
    default:
      return 'Desconhecido';
  }
};

export const translateStatus = (status: string): string => {
  switch (status) {
    case 'active':
      return 'Ativo';
    case 'paused':
      return 'Pausado';
    case 'closed':
      return 'Fechado';
    case 'under_review':
      return 'Em Revisão';
    case 'inactive':
      return 'Inativo';
    default:
      return 'Desconhecido';
  }
};

export const formatCurrency = (value: number | string) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));
};
